@import "./vars";
@import "./font-sizes.css";

* {
  font-family: $f1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: $black;
  transition: all ease-in-out .32s;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, ul, p, a {
  margin: 0;
  padding: 0;
}

h1, h1 span {
  color: $white;
  font-weight: 500;
  font-size: var(--large-text);

  span {
    font-size: var(--larger-text);
  }
}

h2 {
  font-family: $f3;
  font-size: var(--large-text);
}

h3 {
  font-size: var(--reg-plus-text);
}

p {
  line-height: 1.8;
  margin-bottom: 12px;
  color: $black;
  font-size: var(--reg-text)
}

a {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

input {
  background: none;
  border: none;
  border-bottom: solid 1px $white;
  padding: 4px;
  margin: 4px 0;
  color: $white;
  font-size: var(--reg-text);
  width: 100%;
}

label {
  text-align: left;
  text-transform: uppercase;
  font-size: var(--small-text);
  font-weight: 600;
  color: $white;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

small {
  font-size: var(--small-text);
  color: $white;
}

.absolute {
  position: absolute;
}

.action-btn {
  font-family: $f2;
  font-size: var(--reg-text);
}

.btn {
  background: $black;
  color: $white;
  padding: 8px 24px; 
  text-transform: uppercase;
  font-size: var(--reg-text);
  font-weight: 600;

  &:hover {
    background: $white;
    color: $black;
    border-radius: 32px;
  }
}

.btn-sec {
  background: $p2;
  color: $black;
  padding: 12px 32px;
  font-size: var(--reg-text);
  font-weight: 600;
  letter-spacing: 1.6px;
  border-radius: 2px;

  &:hover {
    background: $black;
    color: $white;
    border-radius: 32px;
  }

  @media only screen and (max-width: 480px) {
    padding: 8px 12px;
  }

  @media only screen and (max-width: 380px) {
    font-size: .72rem;
  }
}

.field-wrap {
  margin: 10px 0;
  position: relative;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.row {
      justify-content: space-between;
  }

  &.column {
      flex-direction: column;
      align-items: center;
  }

  &.column-reverse {
      flex-direction: column-reverse;
      align-items: center;
  }
}

.grid {
  display: grid;
}

.no-display {
  display: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-bottom: 0;
}

.margin-auto {
  margin: auto;
}

.page-width {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  @media only screen and (max-width: 380px) {
    padding: 0 12px;
  }
}

.text-center {
  text-align: center;
}

.white-text {
  color: $white;
}

.mg-20 {
  margin: 20px auto;
}

.mw-700 {
  max-width: 700px;
}