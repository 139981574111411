:root {
    --giant-text: 5rem;
    --largest-text: 3rem;
    --larger-text: 2.4rem;
    --large-text: 1.6rem;
    --reg-plus-text: 1.28rem;
    --reg-text: 1rem;
    --small-text: .8rem;
}

@media only screen and (max-width: 680px) {
    :root {
        --giant-text: 4rem;
        --largest-text: 2.4rem;
        --larger-text: 1.92rem;
        --large-text: 1.28rem;
        --reg-plus-text: 1.12rem;
        --reg-text: .8rem;
    }
}

@media only screen and (max-width: 480px) {
    :root {
        --giant-text: 3.2rem;
        --largest-text: 2rem;
        --larger-text: 1.52rem;
        --large-text: 1rem;
        --reg-plus-text: 1rem;
        --reg-text: .8rem; 
    }
}