@import "./vars";
@import "./font-sizes.css";

#mission {
    padding: 40px 0;
    .content-wrap {
        display: flex;

        .img-wrap {
            width: 100%;
            max-width: 400px;
            img {
                max-width: 400px;
            }
        }

        .text-wrap {
            .title-wrap {
                background: $p2;
                padding: 12px 20px;

                @media only screen and (max-width: 480px) {
                    padding: 12px;
                }
            }

            .body-wrap {
                padding: 20px;
    
                p {
                    overflow: hidden; 
                    transition: visibilty ease 2s, opacity ease 2s, height ease 2s, margin-bottom ease 0s, ;
                }
    
                @media only screen and (min-width: 1081px) {
                    .hidden {
                        visibility: visible;
                        opacity: 1;
                        height: auto;
                    }
    
                    .btn {
                        display: none;
                    }
                }
            }
        }

        @media only screen and (max-width: 780px) {
            flex-direction: column;
            align-items: center;
            .img-wrap {
                margin-bottom: -3px;
                img {
                    width: 100%;
                    max-width: 400px;
                }
            }

            .text-wrap {
                max-width: 400px;
                
                .body-wrap {
                    padding: 12px 6px;
                }
            }
        }
    }
}

#about {
    padding-top: 60px;
    .title-wrap {
        padding: 20px;

        .section-title {
            text-align: center;
    
            span {
                font-size: var(--larger-text);
                font-family: $f3;
                display: block;
            }
        }

        .grid {
            display: grid;
            margin: 40px auto 0;    
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-row-gap: 50px;
            grid-column-gap: 20px;

            img {
                width: 100%;
                max-width: 200px;
                margin: auto;

                &:nth-child(2) {
                    width: 100%;
                    max-width: 350px;
                    @media only screen and (max-width: 350px) {
                        max-width: 250px;
                    }
                }
            }
        }
    }
    

    h3 {
        font-family: $f3;
        font-size: var(--larger-text);
        margin-bottom: 4px;
    }
    img {
        max-width: 360px;
        width: 100%;
    }

    h2 {
        margin-bottom: 12px;
    }

    .row {
        .text-wrap {
            max-width: 780px;
        }

        &.one {
            .text-wrap {
                margin-left: 80px;
                margin-right: auto;
                padding-right: 20px;
            }
        }

        &.two {
            .text-wrap {
                margin-left: auto;
                margin-right: 80px;
                padding-left: 20px;
            }
        }

        &.three {
            .text-wrap {
                margin-left: 80px;
                margin-right: auto;
                padding-right: 20px;
            }
        }
    }

    @media only screen and (max-width: 780px) {
        img {
            width: 32%;
        }
        
        .row {
            &.one {
                .text-wrap {
                    margin-left: 40px;
                }
            }
    
            &.two {
                .text-wrap {
                    margin-right: 40px;
                }
            }
    
            &.three {
                .text-wrap {
                    margin-left: 40px;
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        img {
            width: 60%;
        }

        .row {
            margin: 40px 0;

            &.one {
                flex-direction: column;
                img {
                    margin-right: auto;
                }

                .text-wrap {
                    margin-left: 0;
                    padding: 0 20px;
                }
            }

            &.two {
                flex-direction: column-reverse;
                img {
                    margin-left: auto;
                }

                .text-wrap {
                    margin-right: 0;
                    padding: 0 20px;
                }
            }

            &.three {
                flex-direction: column;
                img {
                    margin-right: auto;
                }

                .text-wrap {
                    margin-left: 0;
                    padding: 0 20px;
                }
            }
        }
    }
}

#team {
    .content-wrap {
        padding: 40px 0;
    }

    h2 {
        font-size: var(--largest-text);
        text-align: center;
    }

    h3 {
        font-family: $f3;
        font-size: var(--large-text);
    }

    img {
        width: 50%;
    }

    .row {
        position: relative;

        &.one {
            img {
                margin-left: auto;
            }

            .text-wrap {
                position: absolute;
                left: 40px;
            }
        }

        &.two {
            img {
                margin-right: auto;
            }

            .text-wrap {
                position: absolute;
                right: 40px;
            }
        }

        .text-wrap {
            width: 50%;

            button:hover {
                text-decoration: underline;
            }
        }
    }

    @media only screen and (max-width: 780px) {
        h2 {
            margin: 60px 0 20px;
            text-align: left;
            padding-left: 20px;
        }
        .row {
            flex-direction: column;
            align-items: center;

            &.one {
                img {
                    width: 100%;
                    margin-left: 0;
                    
                }
    
                .text-wrap {
                    position: inherit;
                    width: 100%;
                    left: 0;
                    padding: 20px 20px 0;
                }
            }
    
            &.two {
                img {
                    width: 100%;
                    margin-right: 0;
                }
    
                .text-wrap {
                    position: inherit;
                    width: 100%;
                    right: 0;
                    padding: 20px 20px 0;
                }
            }
        }
    }
}

#faq {
    h2 {
        font-size: var(--largest-text);
        text-align: center;
    }

    .content-wrap {
        text-align: left;
        margin: auto;
        padding: 40px 0;
    }

    .accordion {
        max-width: 600px;
        width: 100%;
        margin: 20px auto;

        .question {
            font-family: $f3;
            font-size: var(--reg-plus-text);
            padding: 10px;
            width: 100%;
            background: $p2;
            cursor: pointer;

            &:hover {
                background: $black;
                color: $p2;
            }
        }

        .answer {
            padding: 0;
            width: 100%;
            border-top: none;
            background: none;
            line-height: 1.5;
            height: 0;
            overflow: hidden;

            &.active {
                height: fit-content;
                padding: 20px;
                border: solid 2px $p2;
            }

            .text {
                opacity: 0;
                transition: all ease-in-out .8s;
                
                &.active {
                    opacity: 1;
                }
            }
        }
    }
}