@import "./vars";

.header-wrap {
    background: $p1;
}

header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .nav-wrap {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
        background: $p1;

        nav {
            padding: 8px;

            a {
                &:hover {
                    color: $black;
                }
            }
    
            .logo-wrap {
                max-width: 140px;
        
                img {
                    width: 100%;
                }
            }
        }

        .toggler {
            display: none;
            padding: 15px 5px;
            width: 50px;
            margin-left: auto;
            cursor: pointer;
            z-index: 10;
            position: absolute;
            top: 10px;
            right: 10px;
            background: rgba(28, 144, 217, 0.6);
            border-radius: 2px;

            .hamburger {
                cursor: pointer;
                width: 100%;
    
                .line {
                    position: relative;
                    width: 100%;
                    height: 2px;
                    background-color: $white;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
                    -webkit-transition: all 0.6s ease;
                    transition: all 0.6s ease;
        
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: -10px;
                        width: 100%;
                        height: 2px;
                        background: inherit;
                    }
        
                    &:after {
                        top: 10px;
                    }
                }

                // turn lines in X
                &.active {
                    -webkit-transform: rotate(135deg);
                            transform: rotate(135deg);
        
                    .line {
                        &:before, &:after {
                            top: 0;
                            -webkit-transform: rotate(90deg);
                                    transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }

    .content-wrap {
        width: 100%;

        .img-wrap {
            margin-bottom: 12px;

            img {
                max-width: 380px;

                @media only screen and (max-width: 580px) {
                    max-width: 300px;
                    width: 100%;
                }
            }
        }

        .btn-wrap {
            margin-top: 42px;
        }

        
    }
}

@media only screen and (max-width: 580px) {
    header {
        .nav-wrap {
            height: 0;
            transition: all ease-in-out .6s;

            &.active {
                height: 100%;
            }

            nav {
                height: 0;
                padding: 0;
                opacity: 0;
                z-index: -1;
                transition: all ease-in-out .3s;
                position: relative;

                &.active {
                    height: 100%;
                    padding: 80px 20px;
                    opacity: 1;
                    z-index: 2;
                }

                .logo-wrap {
                    opacity: 0;
                    z-index: -1;
                    transition: all ease-in-out 1s;

                    &.active {
                        opacity: 1;
                        z-index: 2;
                    }
                }

                &.flex {
                    flex-direction: column;
                    align-items: center;
                }
            }
            
            .toggler {
                display: block;
            }
        }
    }
}